<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer></v-spacer>
      <v-tooltip v-if="$store.state.session.partnerType == 'WHO'" top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :disabled="$store.getters.isReadOnly || !hasOrderRole"
            @click="$router.push({ name: 'internet-order' })"
            icon
          >
            <v-icon>mdi-plus-network-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("add new internet service") }}</span>
      </v-tooltip>
      <wiki slug="services-internet-overview" />
    </v-toolbar>
    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="items"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        sort-by="product_id"
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        local-storage-name="iternetList"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            append-outer-icon="mdi-magnify"
            v-bind:label="$t('search')"
            single-line
            :clearable="!isMobile"
          />
        </template>

        <template v-slot:item.product_descr="{ item }">
          <span>
            {{ item.product_descr ? item.product_descr : "-" }}
          </span>
        </template>

        <template v-slot:item.suppl="{ item }">
          <span v-if="item.suppl">{{ item.suppl }} </span>
          <span v-else-if="item.bbcs">Swisscom (BBCS)</span>
        </template>

        <template v-slot:item.oto="{ item }">
          <span v-if="item.oto">{{ item.oto }}</span>
          <span v-if="item.plug">.{{ item.plug }}</span>
        </template>
        <template v-slot:item.at="{ item }">
          <span v-if="!item.at && item.fiber">{{ "BX" }} </span>
          <span v-else-if="!item.at && item.bbcs">{{ "ADSL" }} </span>
          <span v-else>{{ item.at }}</span>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="
                  item.bbcs
                    ? $router.push({
                        name: 'bbcs-details',
                        params: { bbcsProductId: item.product_id },
                      })
                    : $router.push({
                        name: 'metronet-details',
                        params: { metronetProductId: item.product_id },
                      })
                "
                small
                icon
              >
                <v-icon>mdi-eye-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("show details") }}</span>
          </v-tooltip>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";
import commonAddOns from "@/utils/mixins/commonAddOns";
import DataTableExtended from "@/components/basics/DataTableExtended";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "Internet",
  data: () => ({
    items: [],
    loading: false,
    search: "",
  }),
  mixins: [isMobile, commonAddOns],
  components: {
    Wiki,
    DataTableExtended,
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("product name"),
          value: "product_name",
        },
        {
          text: this.$i18n.t("product descr"),
          value: "product_descr",
        },
        {
          text: this.$i18n.t("type"),
          value: "type",
        },
        {
          text: this.$i18n.t("number"),
          value: "tnr",
        },
        {
          text: this.$i18n.t("username"),
          value: "un",
        },
        {
          text: this.$i18n.t("OTO ID"),
          value: "oto",
        },
        {
          text: this.$i18n.t("supplier"),
          value: "suppl",
        },
        {
          text: this.$i18n.t("connection technology"),
          value: "at",
        },
        { text: "", value: "action", sortable: false },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.items = [];
      this.getItems();
    },
  },
  methods: {
    getItems: function () {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/internet")
        .then((response) => {
          that.items = response.data;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<i18n>
{
  "en": {
    "product name": "Product name",
    "product descr": "Product description",
    "type": "Type",
    "number": "Number",
    "username": "Username",
    "OTO ID": "OTO ID",
    "supplier": "Supplier",
    "search": "Search",
    "show details": "Show details",
    "add new internet service": "Add new internet service",
    "connection technology": "Connection technology"
  }, 
  "de": {
    "product name": "Produkname",
    "product descr": "Produktbeschreibung",
    "type": "Typ",
    "number": "Nummer",
    "username": "Benutzername",
    "OTO ID": "OTO ID",
    "supplier": "Lieferant",
    "search": "suchen",
    "show details": "zeige Details",
    "add new internet service": "neuen Internet Dienst hinzufügen",
    "connection technology": "Anschluss Technologie"
  }
}
</i18n>
