var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-spacer'),(_vm.$store.state.session.partnerType == 'WHO')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.$store.getters.isReadOnly || !_vm.hasOrderRole,"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'internet-order' })}}},on),[_c('v-icon',[_vm._v("mdi-plus-network-outline")])],1)]}}],null,false,3960914714)},[_c('span',[_vm._v(_vm._s(_vm.$t("add new internet service")))])]):_vm._e(),_c('wiki',{attrs:{"slug":"services-internet-overview"}})],1),_c('v-card-text',[_c('data-table-extended',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"mobile-breakpoint":_vm.mbreakpoint,"search":_vm.search,"sort-by":"product_id","hide-default-footer":_vm.items.length <= 15,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [15, 50],
      },"local-storage-name":"iternetList"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"append-outer-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","clearable":!_vm.isMobile},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.product_descr",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.product_descr ? item.product_descr : "-")+" ")])]}},{key:"item.suppl",fn:function({ item }){return [(item.suppl)?_c('span',[_vm._v(_vm._s(item.suppl)+" ")]):(item.bbcs)?_c('span',[_vm._v("Swisscom (BBCS)")]):_vm._e()]}},{key:"item.oto",fn:function({ item }){return [(item.oto)?_c('span',[_vm._v(_vm._s(item.oto))]):_vm._e(),(item.plug)?_c('span',[_vm._v("."+_vm._s(item.plug))]):_vm._e()]}},{key:"item.at",fn:function({ item }){return [(!item.at && item.fiber)?_c('span',[_vm._v(_vm._s("BX")+" ")]):(!item.at && item.bbcs)?_c('span',[_vm._v(_vm._s("ADSL")+" ")]):_c('span',[_vm._v(_vm._s(item.at))])]}},{key:"item.action",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){item.bbcs
                  ? _vm.$router.push({
                      name: 'bbcs-details',
                      params: { bbcsProductId: item.product_id },
                    })
                  : _vm.$router.push({
                      name: 'metronet-details',
                      params: { metronetProductId: item.product_id },
                    })}}},on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("show details")))])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }